<script>
import Location from '@/views/LocationView.vue';
import LogService from '@/services/LogService';
import WpSection from '@/enums/wpSection';

export default {
  extends: Location,
  data: () => ({
    draggableLocMarker: true,
    str: {
      location: {
        crop_location_error: '',
      },
    },
  }),
  methods: {
    async next() {
      this.$refs.placesComponent.$v.$touch();
      LogService.log(this.invalid);
      if (this.$refs.placesComponent.$v.$error) {
        return;
      }
      // before if no street or house number - get place by lat lon
      if (['house', 'street'].some((el) => ['undefined', ''].includes(this.place[el]))) {
        const latLng = {
          lat: this.place.place?.geometry?.location?.lat(),
          lng: this.place.place?.geometry?.location?.lng(),
        };
        await this.getLocationByLatLng(latLng);
      }

      // next button click handler
      await this.saveLocation();

      // go to next page
      this.$router.push({ name: 'drought-risk', params: { ...this.$route.params } });
    },
    back() {
      // back button click handler
      this.$router.push({ name: 'crop-selection', params: { ...this.$route.params } });
    },
    async init() {
      await Promise.all([
        this.$store.commit('SET_APP_LAYOUT', { ...this.appLayout, progress: this.progress }),
        this.$root.$on('buttonNext', this.next),
        this.$root.$on('buttonBack', this.back),
        this.getWordPressStrings([WpSection.LOCATION, WpSection.COMMON_APP]),
      ]);
    },
    updNextDisabledState(val) {
      this.$emit('update:nextDisabled', val);
    },
    async saveLocation() {
      const data = {
        location: {
          latitude: this.place.place?.geometry?.location?.lat(),
          longitude: this.place.place?.geometry?.location?.lng(),
          address: this.place.place?.formatted_address,
          city: this.place.city,
          country: this.place.country,
          country_code: this.place.country_code,
          zip: this.place.zip || undefined,
          street: this.place.street || undefined,
          house: this.place.house || undefined,
          place_id: this.place.id,
          input_type: this.inputType,
        },
      };
      await this.$webAppAPI.postFindRiskAssessmentLocation(data);
    },
    async getStation() {
      this.hasStation = true;
    },
    showErrorPopup() {
      LogService.log('CropLocation show error popup');
      const message = this.str.location.crop_location_error;
      this.showPopup(message);
    },
    onMapClick(e) {
      this.markerDragend(e);
    },
    async getLocationByLatLng(latLng) {
      const addresses = await this.getAddress('', latLng);
      const address = this.findFirstFullAddr(addresses);
      LogService.log('CropLocation getLocationByLatLng', address);
      if (address) {
        this.placeInit = address;
      } else {
        this.showPopup(this.place);
        return Promise.reject(new Error('full address not found'));
      }

      this.inputType = 'tap';

      this.onLocationChange(this.placeInit);

      return true;
    },
    /**
     * try to find first addr from geocode results which has house number and street
     * @returns Object<address> or false
     */
    findFirstFullAddr(addresses) {
      let address = false;
      for (let i = 0; i < addresses.length; i += 1) {
        const addr = { ...this.gPlaceObjToAddr(addresses[i]) };
        if (['house', 'street'].every((key) => !!addr[key])) {
          address = addr;
          break;
        }
      }
      return address;
    },
  },
};
</script>
<style>
</style>
